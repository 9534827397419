var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('div', {
    staticClass: "mt-2 font-size-h3 bold-text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.link,
      "options": {
        width: 200
      }
    }
  })], 1), _c('div', {
    staticClass: "font-size-h5 text-center mx-7 mt-2"
  }, [_vm._v(" Scan this QR code from your device to download our app. ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-chip', {
    staticClass: "cursor-pointer radius-10",
    on: {
      "click": _vm.gotoLink
    }
  }, [_c('MaxText', {
    attrs: {
      "text": _vm.link,
      "max": 34
    }
  })], 1), _c('v-icon', {
    staticClass: "ml-2",
    domProps: {
      "textContent": _vm._s('mdi-content-copy')
    },
    on: {
      "click": function click($event) {
        return _vm.copy(_vm.link);
      }
    }
  })], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }