<template>
    <v-card flat>
        <v-card-title>
            <div class="mt-2 font-size-h3 bold-text">
                {{ title }}
            </div>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <div class="d-flex justify-center align-center">
                        <qrcode :value="link" :options="{ width: 200 }" />
                    </div>
                    <div class="font-size-h5 text-center mx-7 mt-2">
                        Scan this QR code from your device to download our app.
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="text-center">
                        <v-chip @click="gotoLink" class="cursor-pointer radius-10 ">
                            <MaxText :text="link" :max="34" />
                        </v-chip>
                        <v-icon class="ml-2" @click="copy(link)" v-text="'mdi-content-copy'" />
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        link: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
    },
    methods: {
        gotoLink() {
            window.open(this.link, "_blank");
        },
    },
};
</script>
