var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "color": ""
    }
  }, [_c('v-app-bar', {
    attrs: {
      "color": "primary",
      "flat": "",
      "height": "70",
      "tile": ""
    }
  }, [_c('v-spacer'), _c('a', {
    staticClass: "text-center pa-5",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "max-h-70px",
    attrs: {
      "src": "/media/logos/logo_light_155x30.png",
      "alt": ""
    }
  })]), _c('v-spacer')], 1), _c('v-content', {
    staticClass: "mb-12"
  }, [_c('v-container', {
    staticClass: "mb-12"
  }, [_c('v-container', {
    staticClass: "mb-12"
  }, [_c('v-row', {
    staticClass: "my-8"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_vm.message || _vm.secondaryMessage ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.message,
      "secondaryMessage": _vm.secondaryMessage,
      "messageType": "success"
    }
  }) : _vm._e(), _vm.getActivateErrorMessage ? _c('div', {
    staticClass: "my-8"
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_vm.getActivateErrorMessage ? _c('inline-svg', {
    staticClass: "svg-icon",
    attrs: {
      "src": "/media/placeholders/no-devices".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }) : _vm._e()], 1), _vm.getActivateErrorMessage ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.getActivateErrorMessage,
      "messageType": "error"
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_vm.mobile === 'unknown' ? _c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "font-size-h3 bold-text"
  }, [_vm._v("Access our portal")]), _c('div', {
    staticClass: "body-1"
  }, [_vm._v(" Manage team, apps, releases and more ")])])]), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": 'https://testapp.io/images/TestApp.io---Upload-Release.svg'
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none bold-text font-size-h3 pa-8",
    attrs: {
      "x-large": "",
      "href": "/",
      "color": "primary"
    }
  }, [_vm._v("Developer Portal")]), _c('v-spacer')], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "font-size-h3 bold-text"
  }, [_vm._v("Download our app")]), _c('div', {
    staticClass: "body-1"
  }, [_vm._v(" To install your team releases and more ")])])]), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": 'https://testapp.io/images/TestApp.io---App.svg'
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _vm.mobile === 'unknown' || _vm.mobile === 'android' ? _c('v-btn', {
    staticClass: "text-transform-none bold-text font-size-h3",
    class: _vm.$vuetify.breakpoint.mdAndUp ? 'pa-8' : '',
    attrs: {
      "color": "primary",
      "x-large": _vm.$vuetify.breakpoint.mdAndUp
    },
    on: {
      "click": function click($event) {
        return _vm.openDownloadLinks('playStore');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white",
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-android')
    }
  }), _vm._v(" Get our Android ")], 1) : _vm._e(), _vm.mobile === 'unknown' || _vm.mobile === 'ios' ? _c('v-btn', {
    staticClass: "text-transform-none bold-text font-size-h3",
    class: _vm.$vuetify.breakpoint.mdAndUp ? 'pa-8' : '',
    attrs: {
      "color": "primary",
      "x-large": _vm.$vuetify.breakpoint.mdAndUp
    },
    on: {
      "click": function click($event) {
        return _vm.openDownloadLinks('appStore');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white",
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-apple')
    }
  }), _vm._v(" Get our iOS ")], 1) : _vm._e(), _c('v-spacer')], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('k-t-footer'), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.smAndDown ? '' : 800,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.showAndroidOption = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', [_vm.mobile === 'unknown' || _vm.mobile === 'android' ? _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "mt-3 font-size-h3 bold-text"
        }, [_vm._v(" Get our app for Android ")]), _c('div', {
          staticClass: "mb-5 body-1 bold-text"
        }, [_vm._v(" You can use following android stores to download our app. "), _c('br'), _vm._v(" If you need help please "), _c('span', {
          staticClass: "pointer blue--text text-decoration-underline",
          on: {
            "click": _vm.onContactus
          }
        }, [_vm._v("contact us")])]), _c('v-row', {
          attrs: {
            "justify": _vm.$vuetify.breakpoint.smAndDown ? 'center' : 'space-around'
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openDownloadLinks('playStore');
            }
          }
        }, [_c('img', {
          staticClass: "install-img",
          attrs: {
            "src": "/media/svg/google-play.svg"
          }
        })])]), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openDownloadLinks('huaweiStore');
            }
          }
        }, [_c('img', {
          staticClass: "install-img",
          attrs: {
            "src": "/media/svg/haweiistore.png"
          }
        })])]), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openDownloadLinks('samsungStore');
            }
          }
        }, [_c('img', {
          staticClass: "install-img",
          attrs: {
            "src": "/media/svg/samsungstore.png"
          }
        })])])], 1)], 1) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showAndroidOption,
      callback: function callback($$v) {
        _vm.showAndroidOption = $$v;
      },
      expression: "showAndroidOption"
    }
  }), _c('Modal', {
    attrs: {
      "no-py": "",
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.showDownloadModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('app-download-modal', {
          attrs: {
            "link": _vm.selectedLink,
            "title": _vm.selectedTitle
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showDownloadModal,
      callback: function callback($$v) {
        _vm.showDownloadModal = $$v;
      },
      expression: "showDownloadModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }